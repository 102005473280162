import React , {useEffect, useState} from "react";
import ProfilePic from "../images/cooperation.jpg";
import ProfilePic1 from "../images/1.jpg";
import ProfilePic12 from "../images/education.png";
import news2 from "../images/new.jpg";
import '../css/partners.css';
import Navbar from './navbarComp';
import bnrimage from '../images/charity1.jpg'
import Partner1 from '../images/partner1.png'
import Partner2 from '../images/partner2.jpg'
import Partner3 from '../images/partner3.png'
import nordic from '../images/nordic.png'
import helth_tech from '../images/health_tech.png'
import toy from '../images/toy.png'
import mountain from '../images/mountain.jpg'
import { Link } from "react-router-dom";
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import axios from "axios";


const Partners = () => { 
  // Example data for services

  const [news, setNews] = useState([]);
    

 
    const [visibleItems, setVisibleItems] = useState(12); // Initial number of visible items

    const handleShowMore = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 6); // Increment by 10 when Show More button is clicked
    }
    useEffect(() => {
        axios.get('https://server.swcobonga.org/admin/NEWS/')
            .then(res => {
                const formattedNews = res.data.map(item => {
                    console.log('Raw news_description:', item.news_description);
                    let newsDescriptionEditorState = EditorState.createEmpty(); // Default to empty EditorState
                    try {
                        // Try parsing the news_description JSON
                        const parsedDescription = JSON.parse(item.news_description);
                        // If parsing succeeds, create EditorState from the parsed data
                        if (parsedDescription) {
                            newsDescriptionEditorState = EditorState.createWithContent(convertFromRaw(parsedDescription));
                        }
                    } catch (error) {
                        // Log parsing errors
                        console.error('Error parsing news_description:', error);
                    }
                    console.log('Parsed news_description EditorState:', newsDescriptionEditorState);
                    return {
                        ...item,
                        date: new Date(item.date).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        }),
                        news_description: newsDescriptionEditorState
                    };
                });
                setNews(formattedNews);
            })
            .catch(err => console.log(err));
    }, []);


    const [partner, setPartner] = useState([]);  

  useEffect(() => {
    axios.get('https://server.swcobonga.org/admin/Partners/')
      .then(res => {
        const formattedpartners = res.data.map(item => {
          
          return {
            ...item,
            
          };
        });
        setPartner(formattedpartners);
      })
      .catch(err => console.log(err));
  }, []);


  const partners_conents = [
    {
      title: "British Council",

      image: Partner1 // Assign image directly from imported variable
    },
    {
      title: "Book AID",

      image: Partner2  // Assign image directly from imported variable
    },
    {
      title: "ANIKE",
      image: Partner3  // Assign image directly from imported variable
    },

    {
      title: "Nordic Medical Center",
      image: nordic  // Example: Reuse the same image for multiple services
    },
    {
      title: "Health and Teach Foundatainn",
      image: helth_tech  // Example: Reuse a different image
    },
    {
      title: "ToyMakers Guide",
      image: toy  // Example: Reuse a different image
    },
    {
      title: "Mountain Partnership",
      image: mountain  // Example: Reuse a different image
    },
  ];



  return (

    <div className="partners-page-style">
<div className="partners-section-top">
        <br></br>
        <br></br>
        <h1 className="primary-heading">Our Partners</h1>
      </div>
      <div className="partners-section-wrapper">
        {partner.map((partners_conent, index) => (

          <Link to = {`${partners_conent.organization_link}`} className="partners-section-bottom" >
            <a href={`${partners_conent.organization_link}`}><img src={`https://server.swcobonga.org/images/${partners_conent.image}`} alt="" /> </a>


            <h3 className="partners-tilte">{partners_conent.organization}</h3>
          </Link>
        ))} 
      </div>
      <div className="partners-banner">
        <img src={bnrimage} alt="Banner" />
        <div className="slogan-text">" Education is the most powerful weapon which you can use to change the world. "</div>
      </div>
 
      <div className="work-section-top">
        <br></br>
        <br></br>
        <h1 className="primary-heading">Recent Blogs</h1>
      </div>



      <div className="home-news-content-container">

        {news.slice(0, 3).map((item, index) => (


          <div className="home-news-section-bottom" key={index}>
           <Link to={`/blog/${item.news_id}`}> <img href = "/news" src={`https://server.swcobonga.org/images/${item.newsimage}`} alt="" className="home-news-section-bottom-img" /></Link>

            <div className="home-news-snippet"><a href={`/blog/${item.news_id}`}>{item.news_title}</a></div>
            <a href={`/blog/${item.news_id}`}><div className="home-news-snippet-visit-more">Visit More</div></a>
            <div className="container-overlay"></div>
          </div>

        ))}
      </div>

    

      
    </div>
  );
};

export default Partners;
