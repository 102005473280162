import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

function AddGallery() {
    const [gallery_tag, setTag] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const navigate = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

   

    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('gallery_tag', gallery_tag);
        formData.append('image', image);

        axios.post('https://server.swcobonga.org/admin/add_gallery', formData)
            .then(res => {
                console.log(res);
                navigate('/admin/Gallery')
            })
            .catch(err => console.log(err));
    }

    return (
        <div className="app">
            <div><Sidebar /></div>
            <div className="create-content-container ">
                <h1 style={{paddingBottom:'10px'}}>Add Gallery</h1>
                <form onSubmit={handleSubmit}>
                    <div className="content-form-group">
                        <label>Tag:</label>
                        <input
                            type="text"
                            value={gallery_tag}
                            onChange={(e) => setTag(e.target.value)}
                            placeholder='caption (optional)'
                           
                        />
                    </div>
                    

                    <div className="content-form-group">
                        <label>Image:</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} />
                        {imageUrl && (
                            <img src={imageUrl} alt="Selected" className="selected-image" />
                        )}
                    </div>
                    <button type="submit" className="content-primary-button">
                        Add Gallery
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddGallery;
