import React, { useState } from 'react';
import axios from 'axios';
import './Admin_registor.css';
import adminIcon from './admin_icon.png'; // Ensure you have an admin icon image in your project
import { useNavigate } from 'react-router-dom';


const RegisterAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await axios.post('https://server.swcobonga.org/admin/register', {
        username,
        password,
      });
      if (response.data.success) {
        setSuccess('Admin registered successfully');
        setError('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
       
      } else {
        setError(response.data.message);
        setSuccess('');
      }
      navigate('/admin/adm_dashboard');
    } catch (error) {
      setError('An error occurred. Please try again.');
      setSuccess('');
    }
  };

  return (
    <div className="register-admin-container">
      <div className="register-admin-form">
        <img src={adminIcon} alt="Admin Icon" className="admin-icon" />
        <h2>Register Admin</h2>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className='add-admin-input'
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className='add-admin-input'
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className='add-admin-input'
          />
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <button type="submit" className='add-admin-button'>Register</button>
        </form>
      </div>
    </div>
  );
};

export default RegisterAdmin;
