import React , {useState, useEffect} from "react";
import ProfilePic from "../images/empower_ppl.png";
import ProfilePic1 from "../images/water_sanitation.jpeg";
import ProfilePic2 from "../images/cooperation.jpg";
import ProfilePic12 from "../images/education.png";
import ProfilePic13 from "../images/environment.jpg";
import ProfilePic14 from "../images/piece.jpg";
import '../css/AboutPage.css';
import Navbar from './navbarComp';
import bnrimage from '../images/our_program.png'
import Footer from "./footer";
import { Link } from "react-router-dom";
import axios from 'axios';
import {EditorState, convertFromRaw } from 'draft-js';

const Programs = () => {
    const [program, setProgram] = useState([]);

  useEffect(() => {
    axios.get('https://server.swcobonga.org/admin/Programs/')
        .then(res => {
            const formattedProgram = res.data.map(item => {
                console.log('Raw program_description:', item.program_description);
                let programDescriptionEditorState = EditorState.createEmpty(); // Default to empty EditorState
                try {
                    // Try parsing the news_description JSON
                    const parsedDescription = JSON.parse(item.news_description);
                    // If parsing succeeds, create EditorState from the parsed data
                    if (parsedDescription) {
                      programDescriptionEditorState = EditorState.createWithContent(convertFromRaw(parsedDescription));
                    }
                } catch (error) {
                    // Log parsing errors
                    console.error('Error parsing program_description:', error);
                }
                console.log('Parsed program_description EditorState:', programDescriptionEditorState);
                return {
                    ...item,
                    date: new Date(item.date).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    }),
                    program_description: programDescriptionEditorState
                };
            });
            setProgram(formattedProgram);
        })
        .catch(err => console.log(err));
}, []);
    

    return (
        <div className="about-page-style">

            <div><Navbar /></div>
            <div className="the-banner">
                <img src={bnrimage} alt="Banner" />
                <div className="the-banner-text">Our Programs</div>
            </div>
            <div className="work-section-top">
                <br></br>
                <br></br>
                <h2 className="primary-heading">SWCO Projects and Programs</h2>
            </div>
            <div className="homeService-section-wrapper">
                {program.map((service, index) => (
                    <div className="homeService-section-bottom" key={index}>
                        <img src={`https://server.swcobonga.org/images/${service.program_image}`} alt="" /> {/* Use service.image for the image source */}
                        <h3 className="secondary-heading">{service.program_title}</h3>
                        <p className="primary-text">{service.program_highlight}</p>
                        <Link to = {`/program/${service.program_id}`} className="secondary-button">Read More</Link>
                    </div>
                ))}
            </div>
            <div><Footer /></div>
        </div>
    );
};

export default Programs;
