import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Messages() {
    const [Messages, setMessages] = useState([]);

    useEffect(() => {
        axios.get('https://server.swcobonga.org/admin/Messages/')
            .then(res => {
                const formattedMessages = res.data.map(item => {

                    return {
                        ...item,
                        date: new Date(item.date).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          }),

                    };
                });
                setMessages(formattedMessages);
            })
            .catch(err => console.log(err));
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete('https://server.swcobonga.org/admin/message/' + id)
            window.location.reload()
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='app'>
            <Sidebar />
            <div className="admin-content-view-container">
                <div className="header">
                    <h1>All Messages</h1>

                </div>
                <div className="content-body-container">
                    {Messages.map((item, index) => (
                        <div className="content-view-item" key={index}>
                            <div className="view-content-image-container">
                                <p style={{padding:'20px'}}>{'Sender Name: ' + item.comment_name}</p>
                                <p style={{padding:'20px'}}>{'Sender Email: ' + item.comment_email}</p>
                                <p style={{padding:'20px'}}>{'Date: ' + item.date}</p>
                            </div>
                            <div className="view-content-details">
                                <p style={{ fontWeight: 'bold' }}>Message</p>
                                <p style={{ paddingTop: '10px' }}>{item.comment_content}</p>

                                {/* <Editor editorState={item.news_description} readOnly={true} />*/}

                                <div className="content-actions">

                                    <button className="content-delete-button" onClick={e => handleDelete(item.comment_id)}>
                                        <FaTrash /> Delete Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Messages;
