import React, { useState, useEffect } from "react";
import axios from "axios";
import Gallery from "../components/gallerries";

const mainContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  position: "relative",
};

const subContainerStyles = {
  flexGrow: 1,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const centerContainerStyles = {
  width: "80%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
};

const slideStyles = {
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
};

const textContainerStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  color: "#fff",
  fontSize: "clamp(1.25rem, 3vw, 2.5rem)",
  backgroundColor: "rgba(0,0,0,0.6)",
  borderRadius: "1rem",
  padding: "10px",
};

const arrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  ...arrowStyles,
  left: "32px",
};

const rightArrowStyles = {
  ...arrowStyles,
  right: "32px",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  bottom: "20px",
  width: "100%",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};

const gradientBackgroundLeft = {
  background: "linear-gradient(to right, rgb(3, 44, 85), rgb(213, 225, 236))",
};

const gradientBackgroundRight = {
  background: "linear-gradient(to left, rgb(3, 44, 85), rgb(213, 225, 236))",
};

const ImageSlider = () => {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get("https://server.swcobonga.org/admin/Banner/")
      .then((res) => {
        const formattedSlides = res.data.map((item) => ({
          url: `https://server.swcobonga.org/images/${item.image}`,
        }));
        setSlides(formattedSlides);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let interval;
    if (!isHovered) {
      interval = setInterval(() => {
        goToNext();
      }, 3000); // Change slide every 3 seconds
    }

    return () => clearInterval(interval);
  }, [currentIndex, isHovered]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const slideStylesWidthBackground =
    slides.length > 0
      ? {
          ...slideStyles,
          backgroundImage: `url(${slides[currentIndex].url})`,
        }
      : {};

  const overlayStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity as needed
  };

  return (
    <div
      style={{
        ...mainContainerStyles,
        height: isSmallDevice ? "50vh" : "80vh",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ ...subContainerStyles, ...gradientBackgroundLeft }}>
        <div onClick={goToPrevious} style={leftArrowStyles}></div>
      </div>
      <Gallery />
      <div style={{ ...subContainerStyles, ...gradientBackgroundRight }}>
        <div onClick={goToNext} style={rightArrowStyles}></div>
      </div>
    </div>
  );
};

export default ImageSlider;
