import React, { useState, useEffect } from "react";
import ProfilePic from "../images/cooperation.jpg";
import water_san from "../images/water_sanitation_news.jpeg";
import enviro from "../images/enviro.jpg";
import ProfilePic1 from "../images/1.jpg";
import ProfilePic12 from "../images/education.png";
import news2 from "../images/new.jpg";
import '../css/news.css';

import Navbar from './navbarComp';
import bnrimage from '../images/news_bnr.jpg'
import Footer from "./footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
const News = () => {

    const [news, setNews] = useState([]);



    const [visibleItems, setVisibleItems] = useState(12); // Initial number of visible items

    const handleShowMore = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 6); // Increment by 10 when Show More button is clicked
    }
    useEffect(() => {
        axios.get('https://server.swcobonga.org/admin/NEWS/')
            .then(res => {
                const formattedNews = res.data.map(item => {
                    console.log('Raw news_description:', item.news_description);
                    let newsDescriptionEditorState = EditorState.createEmpty(); // Default to empty EditorState
                    try {
                        // Try parsi ng the news_description JSON
                        const parsedDescription = JSON.parse(item.news_description);
                        // If parsing succeeds, create EditorState from the parsed data
                        if (parsedDescription) {
                            newsDescriptionEditorState = EditorState.createWithContent(convertFromRaw(parsedDescription));
                        }
                    } catch (error) {
                        // Log parsing errors
                        console.error('Error parsing news_description:', error);
                    }
                    console.log('Parsed news_description EditorState:', newsDescriptionEditorState);
                    return {
                        ...item,
                        date: new Date(item.date).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        }),
                        news_description: newsDescriptionEditorState
                    };
                });
                setNews(formattedNews);
            })
            .catch(err => console.log(err));
    }, []);



    return (

        <div className="news-page-style">

            <div><Navbar /></div>
            <div className="the-banner">
                <img src={bnrimage} alt="Banner" />
                <div className="the-banner-text">BLOGS</div>
            </div>

            <div className="work-section-top">
                <br></br>
                <br></br>
                <h2 className="primary-heading">Recent Blogs</h2>
            </div>

            <div>

                {news.slice(0, 6).map((item, index) => (
                    <div className="news-content-container" key={index}>
                        <Link to={`/blog/${item.news_id}`}>
                            <div className="news-left-section">
                                <img src={`https://server.swcobonga.org/images/${item.newsimage}`} alt="" /> {/* Use service.image for the image source */}
                                <div className="container-overlay"></div>
                            </div></Link>
                        <div className="news-right-section">
                            <h6 className="latest-news-title">{item.news_title}</h6>
                            <p className="news-p"><a href={`/blog/${item.news_id}`} className="news-a ">{item.headline}</a></p>
                            <hr></hr>
                            <br></br>
                            <div className="date-container">
                                <FontAwesomeIcon icon={faCalendarAlt} className="date-icon" />
                                <div>
                                    <span className="date-value">{item.date}</span>
                                </div>

                            </div>
                            <a href={`/blog/${item.news_id}`}><div className="news-visit-more">Visit More</div></a>




                        </div>

                    </div>
                ))}

            </div>


            <div className="work-section-top">
                <br></br>
                <br></br>
                <h2 className="primary-heading">More Blogs</h2>
            </div>
            <div className="news-section-wrapper">
                {news.slice(6, visibleItems).map((item, index) => (

                    <div className="news-section-bottom" key={index}>
                        <a href={`/blog/${item.news_id}`}> <img src={`http://localhost:8081/images/${item.newsimage}`} alt="" /> </a>

                        <h6 className="more-news-title">{item.news_title}</h6>
                        <a href={`/blog/${item.news_id}`}><p className="more-news-p"><a href={`/blog/${item.news_id}`} className="news-a ">{
                            item.headline}</a></p></a>
                        <div className="date-container">
                            <FontAwesomeIcon icon={faCalendarAlt} className="date-icon" />
                            <div>
                                <span className="date-value">{item.date}</span>

                            </div>


                        </div>

                        <a href={`/blog/${item.news_id}`}><div className="more-news-visit-more">Visit More</div></a>


                    </div>

                ))}
            </div>
            <center> <button className="secondary-button" onClick={handleShowMore}>Show More <FontAwesomeIcon icon={faAngleDown} />
            </button></center>
            <div><Footer /></div>
        </div>
    );
};

export default News;
