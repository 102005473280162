import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';
import adminIcon from './admin_icon.png'; // Ensure you have an admin icon image in your project

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://server.swcobonga.org/admin/login', { username, password });
      if (response.data.success) {
        navigate('/admin/adm_dashboard');

      } else {
        setError('Incorrect username or password');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }

    setUsername('');
    setPassword('');
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-form">
        <img src={adminIcon} alt="Admin Icon" className="admin-icon" />
        <h2 className='admin-input'>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className='admin-input'
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className='admin-input'
          />
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className='admin-submit-button'>Login</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;




