import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

function AddTeam() {
    const [team_name, setName] = useState('');
    const [team_role, setRole] = useState('');
    const [team_education, setEdcuation] = useState('');
    const [team_fb, setFB] = useState('');
    const [team_email, setEmail] = useState('');
    const [team_linkedin, setLinkedin] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const navigate = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        axios.get('https://server.swcobonga.org/admin/createnews/')
            .then(res => console.log(res))
            .catch(err => console.log(err));
    }, [])

    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('team_name', team_name);
        formData.append('team_role', team_role);
        {/**        formData.append('team_education', team_education);
          */}
        formData.append('team_fb', team_fb);
        formData.append('team_email', team_email);
        formData.append('team_linkedin', team_linkedin);
        formData.append('image', image);

        axios.post('https://server.swcobonga.org/admin/add_team', formData)
            .then(res => {
                console.log(res);
                navigate('/admin/Team')
            })
            .catch(err => console.log(err));
    }

    return (
        <div className="app">
            <div><Sidebar /></div> 
            <div className="create-content-container ">
                <h1 style={{ paddingBottom: '10px' }}>Add Team</h1>
                <form onSubmit={handleSubmit}>
                    <div className="content-form-group">
                        <label>Full Name:</label>
                        <input
                            type="text"
                            value={team_name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='FirstName LastName'
                            required
                        />
                    </div>
                    <div className="content-form-group">
                        <label>Role:</label>
                        <input
                            type="text"
                            value={team_role}
                            onChange={(e) => setRole(e.target.value)}
                            placeholder='CEO'
                            required
                        />
                    </div>

                  {/**  <div className="content-form-group">
                        <label>Education:</label>
                        <input
                            type="text"
                            value={team_education}
                            onChange={(e) => setEdcuation(e.target.value)}
                            placeholder='PHD in .. '
                            required
                        />
                    </div> */}


                    <div className="content-form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            value={team_email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='example@gmail.com '
                            required
                        />
                    </div>


                    <div className="content-form-group">
                        <label>FB:</label>
                        <input
                            type="url"
                            value={team_fb}
                            onChange={(e) => setFB(e.target.value)}
                            placeholder='your fb url link '
                            required
                        />
                    </div>

                    <div className="content-form-group">
                        <label>Linkedin:</label>
                        <input
                            type="url"
                            value={team_linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                            placeholder='your Linkedin url link '
                            required
                        />
                    </div>




                    <div className="content-form-group">
                        <label>Photo:</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} />
                        {imageUrl && (
                            <img src={imageUrl} alt="Selected" className="selected-image" />
                        )}
                    </div>
                    <button type="submit" className="content-primary-button">
                        Add Team
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddTeam;
