import React from 'react';
import '../css/Footer.css';
import logoimg from '../images/logo.png';
import { FaFacebook, FaTwitter, FaTelegram, FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { FiChevronsUp } from 'react-icons/fi';

const Footer = () => {
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const currentYear = new Date().getFullYear(); // Get current year

  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <a href='/'><img src={logoimg} alt="Logo" /></a>
            <h3 className='footer-primary-title'>South West Charitable Organization</h3>

          </div>
          {/* <div className="footer-links">
           <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/programs">Programs</a>
          <a href="/news">Blogs</a>
          <a href="/contacts">Contact</a>
          
        </div> */}
          <div className='footer-contact-info-container'>

          <h3 className='footer-primary-title'>Contact Info</h3>
          <div className='footer-address-container'>
            <FaPhone className='address-icon' />
            <p className='footer-primary-text'>+251911386863, +251923344364</p>
          </div>

          <div className='footer-address-container'>
            <FaMapMarkerAlt className='address-icon' />
            <p className='footer-primary-text'>Bonga, Kaffa, Ethiopia</p>
          </div>
          </div>

          <div className='footer-contact-info-container'>

            <h3 className='footer-primary-title'>Follow Us</h3>
            <div className="footer-social">


              <a href="https://www.facebook.com/SWCO.Bonga">
                <FaFacebook />
              </a>
              <a href="https://swcobonga.org/contact/">
                <FaTwitter />
              </a>
              <a href="https://t.me/swcobonga">
                <FaTelegram />
              </a>
              <a href="mailto:swco3565@gmail.com">
                <FaEnvelope />
              </a>
            </div>

          </div>




          <button className="go-to-top-btn" onClick={handleGoToTop}>
            <FiChevronsUp />
          </button>
        </div>

      </footer>
      <div className="footer-bottom">
        <p>Copy Right &copy; {currentYear} - South West Charitable Organization. Dev: <a href='https://deboengineering.com/' style={{color:'rgb(241, 191, 26) ', textDecoration:'underline'}}>Debo Engineering</a> By: <a href='https://t.me/adamseidh' style={{color:'rgb(241, 191, 26) ', textDecoration:'underline'}}>Adam Seid</a></p>
        
      </div>
    </>
  );
};

export default Footer;
