import React, {useState, useEffect} from "react";
import { FaFacebook,FaLinkedin,  FaTwitter, FaInstagram } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import ProfilePic from "../images/profile2.png";
import ProfilePic1 from "../images/1.jpg";
import ProfilePic12 from "../images/profile1.png";
import '../css/team.css'
import axios from "axios";

const Team = () => {

    const [team, setTeam] = useState([]);  

  useEffect(() => {
    axios.get('https://server.swcobonga.org/admin/Team/')
      .then(res => {
        const formattedteam = res.data.map(item => {
          
          return {
            ...item,
            
          };
        });
        setTeam(formattedteam);
      })
      .catch(err => console.log(err));
  }, []);
    const team_infoes = [
        {
            name: "Ashenafi Woldemicheal",
            title:
                "Founder",
            background: "Studying PHD",
            image: ProfilePic12  // Assign image directly from imported variable
        },
        {
            name: "Asayech Worku",
            title:
                " Founder",
            background: "Studied Enivromental Science",
            image: ProfilePic  // Assign image directly from imported variable
        },
        

        
        // Add more services as needed
    ];

    return (
        <div className="work-section-wrapper">
            <div className="work-section-top">
                <h1 className="primary-heading">Our Team</h1>
            </div>
            <div className="team-section-wrapper">
                {team.map((team_info, index) => (
                    <div className="team-section-bottom" key={index}>
                        <img src={`https://server.swcobonga.org/images/${team_info.team_photo}`} alt="" /> {/* Use service.image for the image source */}
                        <h6 className="secondary-heading">{team_info.team_name}</h6>
                        <p className="primary-text">{team_info.team_role}</p>

                        <div className="team-social-midea">
                            <a href= {`${team_info.team_fb}`}>
                                <FaFacebook />
                            </a>
                            <a href={`${team_info.team_email}`}>
                                <FiMail />
                            </a>
                            <a href={`${team_info.team_linkedin}`}>
                                <FaLinkedin />
                            </a>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
