import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './create_news.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

function CreateNews() {
  const [news_title, setTitle] = useState('');
  const [news_headline, setHeadline] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [date, setDate] = useState(new Date());

  const navigate = useNavigate()

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    axios.get('https://server.swcobonga.org/admin/createnews/')
      .then(res => console.log(res))
      .catch(err => console.log(err));
  }, [])

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('news_title', news_title);
    formData.append('news_headline', news_headline);
    formData.append('editorState', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    formData.append('image', image);

    axios.post('https://server.swcobonga.org/admin/createnews', formData)
      .then(res => {
        console.log(res);
        navigate('/admin/NEWS')
      })
      .catch(err => console.log(err));
  }

  return (
    <div className="app">
      <div><Sidebar /></div>
      <div className="create-content-container ">
        <h1>Create Blog</h1>
        <form onSubmit={handleSubmit}>
          <div className="content-form-group">
            <label>Title:</label>
            <input
              type="text"
              value={news_title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="content-form-group">
            <label>Headline:</label>
            <input
              type="text"
              value={news_headline}
              onChange={(e) => setHeadline(e.target.value)}
              required
            />
          </div>


          {
            /**
             * 
                    <div className="form-group">
                      <label>Date:</label>
                      <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="MMMM d, yyyy"
                        className="date-picker"
                      />
                    </div>
             */
          }
          <div className="content-form-group">
            <label>Description:</label>
            <div className="content-editor-container">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                placeholder='write detail of the news here...'
              />
            </div>
          </div>
          <div className="content-form-group">
            <label>Image:</label>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {imageUrl && (
              <img src={imageUrl} alt="Selected" className="selected-image" />
            )}
          </div>
          <button type="submit" className="content-primary-button">
            Pubish Blog
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateNews;
