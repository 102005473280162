import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

function Admin_programs() {
  const [programs, setPrograms] = useState([]);  

  useEffect(() => {
    axios.get('https://server.swcobonga.org/admin/programs/')
      .then(res => {
        const formattedPrograms = res.data.map(item => {
          console.log('Raw programs_description:', item.program_description);
          let programsDescriptionEditorState = EditorState.createEmpty(); // Default to empty EditorState
          try {
            // Try parsing the news_description JSON
            const parsedDescription = JSON.parse(item.news_description);
            // If parsing succeeds, create EditorState from the parsed data
            if (parsedDescription) {
                programsDescriptionEditorState = EditorState.createWithContent(convertFromRaw(parsedDescription));
            }
          } catch (error) {
            // Log parsing errors
            console.error('Error parsing programs_description:', error);
          }
          console.log('Parsed news_description EditorState:', programsDescriptionEditorState);
          return {
            ...item,
            date: new Date(item.date).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }),
            program_description: programsDescriptionEditorState
          };
        });
        setPrograms(formattedPrograms);
      })
      .catch(err => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try{
        await axios.delete('https://server.swcobonga.org/admin/Programs/' + id)
        window.location.reload()
    } catch(err){
        console.log(err);
    }
  }

  return (
    <div className='app'>
      <Sidebar />
      <div className="admin-content-view-container">
        <div className="header">
          <h1>Programs</h1>
          <Link to="/admin/create_program" className="create-more-content-button">
            <FaPlus />
            Create Program
          </Link>
        </div>
        <div className="content-body-container">
          {programs.map((item, index) => (
            <div className="content-view-item" key={index}>
              <div className="view-content-image-container">
                <img src={`https://server.swcobonga.org/images/${item.program_image}`} alt="News" />
              </div>
              <div className="view-content-details">
                <h2>{item.program_title}</h2>
                <p style={{paddingTop:'10px'}}>{item.program_highlight}</p>
                {/* <Editor editorState={item.news_description} readOnly={true} />*/}
                
                <div className="content-actions">
                  <Link to ={`/admin/edit_program/${item.program_id}` }className="content-edit-button">
                    <FaEdit />
                  </Link>
                  <button className="content-delete-button" onClick={e=> handleDelete(item.program_id)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Admin_programs;
