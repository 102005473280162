import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

function Team() {
  const [team, setTeam] = useState([]);  

  useEffect(() => {
    axios.get('https://server.swcobonga.org/admin/Team/')
      .then(res => {
        const formattedteam = res.data.map(item => {
          
          return {
            ...item,
            
          };
        });
        setTeam(formattedteam);
      })
      .catch(err => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try{
        await axios.delete('https://server.swcobonga.org/admin/team/' + id)
        window.location.reload()
    } catch(err){
        console.log(err);
    }
  }

  return (
    <div className='app'>
      <Sidebar />
      <div className="admin-content-view-container">
        <div className="header">
          <h1>Team</h1>
          <Link to="/admin/add_team" className="create-more-content-button">
            <FaPlus />
            Add Team
          </Link>
        </div>
        <div className="content-body-container">
          {team.map((item, index) => (
            <div className="content-view-item" key={index}>
              <div className="view-content-image-container">
                <img src={`https://server.swcobonga.org/images/${item.team_photo}`} alt="team" />
              </div>
              <div className="view-content-details">
                <h2>{item.team_name}</h2>
                <p style={{paddingTop:'10px'}}>{item.team_role}</p>
                <p style={{paddingTop:'10px'}}>{item.team_fb}</p>
                <p style={{paddingTop:'10px'}}>{item.team_email}</p>
                <p style={{paddingTop:'10px'}}>{item.team_linkedin}</p>
                {/* <Editor editorState={item.news_description} readOnly={true} />*/}
                
                <div className="content-actions">
                 {/** <Link to ={`/admin/editnews/${item.news_id}` }className="content-edit-button">
                    <FaEdit />
                  </Link> */}
                  <button className="content-delete-button" onClick={e=> handleDelete(item.team_id)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
